import React, { useEffect } from 'react'
import Aos from "aos";
import "aos/dist/aos.css";
import "./livestockSolutionsOverview.css"


export default function LivestockSolutionsOverview() {

    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);

    // <div className="segmentOverview-tab-horizontal" data-aos="fade-left" data-aos-duration="2000"></div>

    return (
        <div>
            <div className="livestockSolutionsOverview-layout-42">
                <div className="livestockSolutionsOverview-content2">
                    <div className="livestockSolutionsOverview-column2" data-aos="fade-right" data-aos-duration="700">
                        <div className="livestockSolutionsOverview-heading">
                            CIC’s LIVESTOCK SOLUTIONS
                        </div>
                    </div>
                    <div className="livestockSolutionsOverview-column3">
                        <div className="livestockSolutionsOverview-text">
                            <p className="livestockSolutionsOverview-this-was-done" data-aos="fade-left" data-aos-duration="500">
                                CIC’s LIVESTOCK SOLUTIONS cluster is one of the few fully integrated poultry
                                solutions providers in Sri Lanka. Our facilities include, a fully automated feed
                                mill, breeder farms, state-of-the-art hatchery, broiler farms and a modern chicken
                                processing plant that serve as a vital link in the Country’s poultry supply chain.
                            </p>
                            <p className="livestockSolutionsOverview-this-was-done">&nbsp;</p>
                            <p className="livestockSolutionsOverview-this-was-done" data-aos="fade-left" data-aos-duration="700">
                                Both our fully automated feed mill and state of the art poultry processing plant both operate in
                                accordance with international standards, including the ISO 22000:2018 certification as well as
                                GMP and HACCP accreditations.
                            </p>
                            <p className="livestockSolutionsOverview-this-was-done">&nbsp;</p>
                            <p className="livestockSolutionsOverview-this-was-done" data-aos="fade-left" data-aos-duration="700">
                                With a capacity of 7,000 MT per month our feed mill is one of the largest and most sophisticated
                                in Sri Lanka. Our poultry processing plant was constructed at an investment of over Rs. 1.4
                                Billion has been in operation for the past 17 years and process the “CIC Besto” range of chicken.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
